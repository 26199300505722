// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import 'bootstrap';

// Font Awesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/v4-shims.scss";


@import "bootstrap-modifiers";
@import 'custom';
@import 'scoresheet';

main {
    min-height: 300px;
}
